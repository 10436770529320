h1, h2, h3, h4, h5, h6 {
	font-family: 'Hind Siliguri', sans-serif;
	color: $Aggdata_dkblue;
}

h1 {
	font-size: rem-calc(45);
	line-height: rem-calc(50);
}

h2 {
	font-size: rem-calc(35);
	line-height: rem-calc(40);
}

h3 {
	font-size: rem-calc(30);
	line-height: rem-calc(34);
}

h4 {
	font-size: rem-calc(25);
	line-height: rem-calc(30);
}

h5 {
	font-size: rem-calc(21);
	line-height: rem-calc(26);
}

.main {
	p {
		font-family: 'Hind Siliguri', sans-serif;
		font-size: rem-calc(18);
		line-height: rem-calc(24);
		color: $steel;
	}

	small {
		font-family: 'Hind Siliguri', sans-serif;
		font-size: rem-calc(16);
		line-height: rem-calc(21);
		color: $steel;
	}


	.item-list ul,
	.item-list ol {
		margin: 0;

		li {
			font-size: rem-calc(18);
			line-height: rem-calc(24);
			color: $steel;
			margin-bottom: rem-calc(16);
			margin-left: rem-calc(33);
		}
	}

}

.main table {
	width: 100%;
	thead {
		tr th {
			font-size: rem-calc(21);
			line-height: rem-calc(26);
			color: $Aggdata_dkblue;
			padding: rem-calc(10);
			background-color: $Aggdata_green;
			border-bottom: 0;
			font-weight: 400;
			border: 1px solid $iron;

			a {
				color: $Aggdata_dkblue;
			}
		}
	}
	tbody {
		tr td {
			font-size: rem-calc(18);
			line-height: rem-calc(24);
			color: $steel;
			padding: rem-calc(10);
			background-color: $silver;
			border: 1px solid $iron;

			button, .button,
      button.form-submit,
			button.add-to-cart {
				margin: 0;
				padding: rem-calc(5) rem-calc(15);
        min-width: rem-calc(125);
        white-space: nowrap;
        font-size: rem-calc(14);
			}
		}

		tr.even td {
			background-color: lighten($silver, 5%);
		}

    tr.pastpurchase {
      .views-field-commerce-price {
        color: $Aggdata_green;
        white-space: nowrap;
        .has-tip {
          position: relative;
          top: -1px;
          margin-left: rem-calc(3);
        }
      }
    }
    .has-tip, .help-icon {
      width: 18px;
      height: 18px;
      background: url('#{$img-path}/icons/tooltip_icon.svg') no-repeat;
      text-align: left;
      text-indent: -9999px;
      display: inline-block;
      border: none;
      margin-left: 5px;
      &:hover, &.open {
        background: url('#{$img-path}/icons/tooltip_icon_hover.svg') no-repeat;
        border: none;
        cursor: default;
      }
    }
    .qtip-link {
      border: none;
    }
	}
  @media #{$small-only} {
    thead {
      display: none;
    }
    tbody td {
      display: block;
    }
    tbody tr td:first-child {
      background: $Aggdata_green;
      color: $white;
      a {
        color: $white;
      }
    }
	  tbody td:not(:first-child):before {
      content: attr(data-th);
      display: inline-block;
      width: 40%;
    }
  }
}

.main table thead a {
	color: $Aggdata_dkblue;

	&:hover {
		color: darken($Aggdata_dkblue, 10%);
	}
}

.main {
  .view-user-licensed-files-names {
    .button {
      padding: rem-calc(10) rem-calc(16);
      margin-bottom: rem-calc(10);
    }
    table {
      caption h3 {
        font-size: rem-calc(24);
        line-height: rem-calc(26);
        color: $Aggdata_blue;
      }
      @media #{$small-only} {
        tbody td:before {
          content: attr(data-th);
          display: inline-block;
          width: 40%;
        }
        tbody tr td:first-child {
          background: inherit;
          color: $steel;
          a {
            color: $Aggdata_blue;
          }
        }
      }
    }
  }
}

.main a {
	color: $Aggdata_blue;

	&:hover {
		color: $Aggdata_blue;
		text-decoration: underline;
	}
}

#page-title {
	margin-bottom: rem-calc(30);
  margin-left: ($column-gutter/2);
  margin-right: ($column-gutter/2);
}

.main blockquote {
	font-family: 'Hind Siliguri', sans-serif;
	font-size: rem-calc(26);
	line-height: rem-calc(32);
	color: $Aggdata_dkblue;
	border: 0;
	margin-top: rem-calc(30);
	padding-right: rem-calc(40);
	padding-left: rem-calc(40);

	> p {
		font-family: 'Hind Siliguri', sans-serif;
		font-size: rem-calc(26);
		line-height: rem-calc(32);
		color: $Aggdata_dkblue;
		margin: 0;

		&:before {
			content: "";
			display: inline-block;
			width: 67px;
			height: 49px;
			background: url("#{$img-path}/icons/quote_open_icon.png") no-repeat left center;
			margin-right: rem-calc(20);
			margin-left: rem-calc(-20);
		}

		&:after {
			content: "";
			display: inline-block;
			width: 37px;
			height: 27px;
			background: url("#{$img-path}/icons/quote_close_icon.png") no-repeat;
			margin-left: rem-calc(20);
			margin-bottom: rem-calc(-11);
		}
	}
}

cite {
	font-size: rem-calc(14);
	line-height: rem-calc(32);
	padding-right: rem-calc(60);
	display: block;
	text-align: right;
	color: $Aggdata_blue;
}

div.status,
div.warning,
div.error {
	background-image: none;
	background-repeat: no-repeat;
	color: $Aggdata_green;

	ul {
		list-style: none;
		margin: 0;
		margin: rem-calc(10) 0;
		border: 1px solid $Aggdata_green;
		background-color: $white;

		li {
			padding: rem-calc(20);
		}
	}

	.added {
    &:before {
      content: url("#{$img-path}/icons/checkmark_icon.svg");
      display: inline-block;
      width: rem-calc(35);
      height: rem-calc(35);
      margin-right: rem-calc(10);
      position: relative;
      top: rem-calc(9);
      margin-top: rem-calc(-9);
    }
	}

	.discount {
    &:before {
      content: url("#{$img-path}/icons/alert_icon.svg");
      display: inline-block;
      width: rem-calc(35);
      height: rem-calc(35);
      margin-right: rem-calc(10);
      position: relative;
      top: rem-calc(9);
      margin-top: rem-calc(-9);
    }
	}
}

div.warning {
	color: $orange;

	ul {
		border: 1px solid $orange;
	}
}

div.error {
	color: $red;

	ul {
		border: 1px solid $red;
	}
}
// Discount tooltips
.discounts-message {
  width: 20px;
  height: 20px;
  background: url('#{$img-path}/icons/tooltip_icon.svg') no-repeat;
  text-align: left;
  text-indent: -9999px;
  display: inline-block;
  border: none;
  &:hover, &.open {
    background: url('#{$img-path}/icons/tooltip_icon_hover.svg') no-repeat;
    border: none;
    cursor: default;
  }
  @media #{$small-only} {
    float: left;
  }
}
// Tooltip content style.
.tooltip,
.tooltip.tip-top {
  border: 1px solid lighten($orange, 10%);
  > .nub {
    display: none;
  }
}
.qtip {
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.3;
  padding: 0.75rem;
  color: #666666;
  background: #ffe8d0;
  border: 1px solid #ff8f16;
}
.qtip-content {
  max-height: 25vh;
  overflow-y: scroll;

  @media #{$small-only} {
    max-height: 50vh;
  }
}

// Pagination.
ul.pagination {
  @media #{$small-only} {
    li:not(.first):not(.last):not(.arrow):not(.current) {
      display: none;
    }
    li {
      margin-left: 0 !important;
      a {
        padding-left: rem-calc(4);
        padding-right: rem-calc(4);
      }
    }
  }
}

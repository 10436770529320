// Non-modular or client styles for .l-footer region.
.agg-footer {
	background-color: $Aggdata_dkblue;
	padding-top: rem-calc(50);
  color: $white;

  div.columns {
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);
    @media #{$small-only} {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

	.footer-first {
    text-align: center;
    @media #{$medium-up} {
      text-align: left;
    }
		h2 {
			color: $white;
			font-size: rem-calc(21);
			line-height: rem-calc(32);
			font-weight: bold;
		}

		.block-menu-menu-footer {
			.menu {
				margin: 0;
				li {
					display: inline-block;

					a {
						font-size: rem-calc(14);
						line-height: rem-calc(26);
						color: $gainsboro;
						padding-right: rem-calc(5);
						padding-left: rem-calc(5);
            @media #{$medium-only} {
              padding-left: 0; 
            }
					}

					&:first-child a {
						padding-left: 0;
					}
					&:last-child a {
						padding-right: 0;
					}

					&:after {
            content: "|";
            color: $gainsboro;
            display: inline-block;
            @media #{$medium-only} {
              content: "";
            }
					}

					&:last-child:after {
						content: "";
					}
				}

			}
		}

		#social-media {
			color: $white;
      ul {
        margin-left: 0;
        li {
          display: inline-block;
          list-style: none;
          margin-left: 0;
          a {
            width: 37px;
            height: 37px;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
            display: block;
            @media #{$medium-only} {
              width: 32px;
              height: 32px;
            }

            &:hover {
              opacity: 0.85;
              transition: opacity 300ms ease-out;
            }
          }
        }
        li:not(:first-child) {
          margin-left: rem-calc(8);
          @media #{$medium-only} {
            margin-left: 0;
          }
        }
      }

			#facebook {
				background: url("#{$img-path}/icons/facebook.svg") no-repeat;
			}

			#linkedin {
				background: url("#{$img-path}/icons/linkedin.svg") no-repeat;
			}

			#twitter {
				background: url("#{$img-path}/icons/twitter.svg") no-repeat;
			}

			#googleplus {
				background: url("#{$img-path}/icons/google_plus.svg") no-repeat;
			}
		}
	}

	.footer-second {

		p, h3 {
			color: $white;
			font-size: rem-calc(21);
			line-height: rem-calc(32);
			font-weight: bold;
			text-align: center;
		}

		h3 {
			margin-bottom: rem-calc(35);
		}
	}

	.footer-third {
    background: lighten($Aggdata_dkblue, 10%);
    text-align: center;
    @media #{$medium-up} {
      text-align: left;
      background: none;
    }
		h2, label {
			color: $white;
		}

		h2 {
			font-size: rem-calc(21);
			line-height: rem-calc(32);
			font-weight: bold;
      margin-bottom: rem-calc(8);
		}

		label {
			padding-bottom: rem-calc(26);
			font-size: rem-calc(14);
			line-height: rem-calc(21);
		}

    #edit-intro {
      margin-top: 0;
      font-family: Lato, sans-serif;
      font-style: italic;
    }

		input[type="text"], input[type="password"], input[type="email"], textarea {
			border-radius: 6px;
			font-size: rem-calc(13);
			line-height: rem-calc(27);
			height: 52px;
			background-color: $ghost;
			text-transform: capitalize;
		}

		button, .button {
			width: 100%;
			border-radius: 6px;
			background-color: $Aggdata_blue;
			text-transform: uppercase;
			font-size: rem-calc(16);
			line-height: rem-calc(27);
			font-weight: bold;

			&:hover {
				background-color: lighten($Aggdata_blue, 10%);
			}
		}
	}

}

.agg-copyright {
	background-color: $smoke;

	.l-footer {
		border: 0;
		background-color: $smoke;
		margin-bottom: 0;

		.copyright {
			color: $jumbo;
			font-size: rem-calc(16);
			line-height: rem-calc(26);
			text-align: center;
		}
	}
}




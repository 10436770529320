// Non-modular or client styles for .l-header region.
.l-header {
  &:before, &:after {
    content: " ";
    display: table;
    clear: both;
  }
  .l-header-region {
    position: relative;
    background: $Aggdata_green;
    padding-bottom: rem-calc(7);
    @media #{$medium-up} {
      background: none;
      padding-top: rem-calc(18);
    }


    .block-system-user-menu, 
    .block-aggdata-cart-header-block {
      float: right;
    }

    .block-aggdata-cart-header-block {
      color: $Aggdata_blue;
      font-size: rem-calc(13);
      line-height: rem-calc(27);
      margin-right: rem-calc(8);

      .aggdata-cart-header-block-items {
        background-image: url("#{$img-path}/icons/cart_icon_blue.svg");
        background-repeat: no-repeat;
        display: inline-block;
        background-size: 20px;
        width: 20px;
        height: 20px;
        position: relative;
        top: 4px;

        .aggdata-cart-header-block-quantity {
          display: none;
        }
      }

      .aggdata-cart-header-block-checkout {
        display: none;
      }
    }

    .block-system-user-menu {
      clear: both;
      .menu {
        margin-bottom: 0;
        margin-top: rem-calc(3);
        margin-left: 0;
        @media #{$medium-up} {
          margin-top: 0; 
        }
        li {
          list-style-type: none;
          display: inline-block;

          a {
            position: relative;
            text-decoration: none;
            text-align: center;
            display: inline-block;
            font-size: rem-calc(13);
            font-weight: bold;
            padding: rem-calc(0 5 0 0);
            color: $white;
            border-right: 1px solid $white;
            
            @media #{$medium-up} {
              border-radius: 5px;
              background-color: $Aggdata_blue;
              transition: background-color 300ms ease-out;
              padding: rem-calc(10 23);
              border: none;

              &:hover {
                background-color: darken($Aggdata_blue, 10%);
              }
            }
          }
          &.search {
            @media #{$medium-up} {
              display: none;
            }
          }
          &.user-account {
            @media #{$small-only} {
              display: none;
            }
          }
          &.last a {
            border: none;
          }
        }
      }
    }
  }

  .l-header-region-2 {

    > .columns {
      margin-top: rem-calc(32);
    }

    .title-area {
      margin: 0 auto;
      width: 250px;
      height: 70px;
      @media #{$medium-up} {
        margin: 0;
        width: auto;
      }
      .name {
        margin-top: rem-calc(-5);
        .site-slogan {
          @media #{$medium-up} {
            margin-top: rem-calc(-20);
            margin-left: rem-calc(115);
          }
          color: $jumbo;
          font-family: Lato, sans-serif;
          font-style: italic;
        }
      }
      h1 a {
        display: block;
        width: 200px;
        height: 56px;
        text-indent: 200%;
        white-space: nowrap;
        overflow: hidden;
        background: url("#{$img-path}/logo.svg") no-repeat;
        background-size: 200px;
        margin-top: rem-calc(-10);
        margin-bottom: rem-calc(-10);
      }
    }

    .search-column {
      display: none;
      padding-left: ($column-gutter);
      padding-right: ($column-gutter);
      @media #{$medium-up} {
        display: block;
        padding-left: ($column-gutter)/2;
        padding-right: ($column-gutter)/2;
      }
    }
  }
}

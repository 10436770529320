.top-bar {
  height: 150px;
  &.search-displaying {
    height: 230px;
    &.expanded {
      height: auto;
    }
  }
  .row {
    max-width: $row-width;
  }

  .top-bar-section {
    margin-top: rem-calc(25);

    .main-menu-column {
      padding: 0;
    
      #main-menu {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        @media #{$medium-up} {
          display: table;
          padding-bottom: rem-calc(8);
        }
        li {
          border-top: 1px solid $grey;
          padding: 0 10px;
          @media #{$medium-up} {
            border: none;
          }
          @media #{$small-up} {
            padding: 0;
          }
          a {
            font-size: rem-calc(18);
            line-height: rem-calc(45);

            &.active, &.active-trail {
              background-color: $Aggdata_blue;
              color: $white;
            }
          }
          @media #{$small-only} {
            a {
              background: none;
              color: $jumbo;
            }
            .dropdown .back.js-generated a {

              &:before {
                content: "\2039";
                color: $jumbo;
                font-weight: normal;
                font-size: 2.5rem;
                position: relative;
                top: 4px;
                margin-right: rem-calc(10);
              }
            }
          }
        }

        > li {
          text-align: center;
          &.expanded:hover a {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }
          @media #{$medium-up} {
            display: table-cell;
            float: none;
          }

          > a {
            @media #{$medium-up} {
              border-radius: rem-calc(6);
            }
            @media #{$medium-only} {
              padding-left: .35rem;
              padding-right: .35rem;
            }
            &:hover {
              background-color: $Aggdata_green;
              color: darken($Aggdata_dkblue, 10%);
            }
          }

          li {
            text-align: left;
          }
        }

        @media #{$medium-up} {
          .dropdown > li.first > a {
            border-top-right-radius: 6px;
          }
          .dropdown > li.last > a {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
          }
          .dropdown > li > a.active {
            background: $Aggdata_green;
            color: $Aggdata_dkblue;
          }
        }
      }
    }

    ul li {
      background-color: transparent;
    }

    .dropdown li.parent-link a:hover {
      background: $Aggdata_blue;
      color: $Aggdata_dkblue;
    }

    .has-dropdown a:after {
      margin-right: 16%;
    }

    .has-dropdown > a.active:after, 
    .has-dropdown > a.active-trail:after {
      border-color: transparent transparent transparent $white;
    }

    @media #{$medium-up} {
      .has-dropdown > a.active:after,
      .has-dropdown > a.active-trail:after,
      .has-dropdown:hover > a:after {
        border-color: $white transparent transparent transparent;
      }
    }

    .navbar-blocks {

      .block-aggdata-cart-header-block {
        border: 1px solid $Aggdata_blue;
        border-radius: rem-calc(6);
        line-height: rem-calc(32);
        width: 100%;
        margin: 0 0 0 auto;
        text-align: right;
        @media #{$large} {
          width: 173px;
        }

        .aggdata-cart-header-block-items {
          background-image: url("#{$img-path}/icons/cart_icon_blue.svg");
          background-repeat: no-repeat;
          display: inline-block;
          background-size: 35px;
          background-position-x: 10%;
          width: 39%;

          .aggdata-cart-header-block-quantity {
            display: inline-block;
            text-align: center;
            width: 40%;
            font-size: rem-calc(20);
            font-weight: bold;
            position: relative;
            top: 2px;
          }
        }

        .aggdata-cart-header-block-checkout {
          background: $Aggdata_blue;
          border-top-right-radius: rem-calc(6);
          border-bottom-right-radius: rem-calc(6);
          display: inline-block;
          color: white;
          line-height: rem-calc(40);
          width: 58%;
          text-align: center;
          &:hover {
            background: darken($Aggdata_blue, 10%);
          }
        }
      }
    }
  }

  .toggle-topbar.menu-icon {
    position: absolute;
    left: 0;
    margin-top: rem-calc(-20);
    a {
      padding: 0 rem-calc(24) 0 rem-calc(15);
    }
  }
  &.expanded .toggle-topbar.menu-icon a span::after {
    box-shadow: 0 0 0 1px lighten($Aggdata_green, 30%), 0 7px 0 1px lighten($Aggdata_green, 30%), 0 14px 0 1px lighten($Aggdata_green, 30%);
  }
}

// The last child when logged in will be My Account and hidden,
// so, the second to last becomes the one that is last.
.logged-in .top-bar .top-bar-section #main-menu > li:nth-last-child(2) {
  margin-right: 0;
}
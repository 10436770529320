.node-news-article {
	&.node-teaser,
	&.view-mode-full {
		margin-bottom: rem-calc(40);
		background-color: $silver;
		padding: rem-calc(20);
		border-radius: 6px;

		.group-left {
			width: 25%;
			float: left;
		}

		.group-right {
			width: 75%;
			float: left;
			padding-left: rem-calc(20);
		}

		&.no-img {
			.group-left {
				display: none;
			}
			.group-right {
				width: 100%;
        padding-left: 0;
			}
		}

		.field-name-field-news-image img {
			border: 5px solid #FFF;
			display: block;
			margin: 0 auto;
			margin-bottom: rem-calc(10);
		}

		.field-name-title {
			margin-bottom: rem-calc(20);
			a {
				font-size: rem-calc(25);
				line-height: rem-calc(30);
				color: $Aggdata_dkblue;
				margin: 0;
			}
		}

		.field-name-author,
		.field-name-post-date,
		.field-name-field-tags {
			float: left;
			margin-right: rem-calc(48);
			color: $Aggdata_dkblue;
			font-size: rem-calc(16);
			line-height: rem-calc(20);
		}

		.field-name-author .field-items .field-item {
			text-transform: capitalize;
			color: $Aggdata_dkblue;
      &:before {
        content: url("#{$img-path}/icons/person_icon.svg");
        display: inline-block;
        width: rem-calc(20);
        height: rem-calc(20);
        margin-right: rem-calc(10);
        position: relative;
        top: rem-calc(3);
      }
		}

		.field-name-post-date .field-items .field-item {
      &:before {
        content: url("#{$img-path}/icons/clock_icon.svg");
        display: inline-block;
        width: rem-calc(20);
        height: rem-calc(20);
        margin-right: rem-calc(10);
        position: relative;
        top: rem-calc(3);
      }
		}

		.field-name-field-tags {
      &:before {
        content: url("#{$img-path}/icons/tag_icon.svg");
        display: inline-block;
        width: rem-calc(20);
        height: rem-calc(20);
        margin-right: rem-calc(10);
        position: relative;
        top: rem-calc(3);
      }
			margin-right: 0;

			ul {
				list-style: none;
				margin-left: 0;
        display: inline;

				li {
					display: inline;
					margin-right: rem-calc(5);

					&:after {
						content: ",";
					}

					&:last-child:after {
						content: "";
					}
				}
			}
		}

		.field-group-div {
			background: $white_smoke;
			padding-left: rem-calc(25);
			padding-top: rem-calc(12);
			padding-bottom: rem-calc(12);
			margin-bottom: rem-calc(20);

			&:after {
				content: "";
				display: table;
				clear: both;
			}
		}

		.body {
			color: $steel;
		}
	}

	&.view-mode-full {
		.field-name-field-news-image img {
			width: 100%;
		}
	}
}

.node-retail-news {
	&.node-teaser {
		margin-bottom: rem-calc(40);
		background-color: lighten($Aggdata_blue, 50%);
		padding: rem-calc(20);
		border-radius: 6px;

		.field-name-title {
			margin-bottom: rem-calc(20);
			a {
				font-size: rem-calc(25);
				line-height: rem-calc(30);
				color: $Aggdata_dkblue;
				margin: 0;
			}
		}

		.field-name-author,
		.field-name-post-date,
		.field-name-field-tags {
			float: left;
			margin-right: rem-calc(48);
			color: $Aggdata_dkblue;
			font-size: rem-calc(16);
			line-height: rem-calc(20);
		}

		.field-name-author .field-items .field-item {
			text-transform: capitalize;
			color: $Aggdata_dkblue;
      &:before {
        content: url("#{$img-path}/icons/person_icon.svg");
        display: inline-block;
        width: rem-calc(20);
        height: rem-calc(20);
        margin-right: rem-calc(10);
        position: relative;
        top: rem-calc(3);
      }
		}

		.field-name-post-date .field-items .field-item {
      &:before {
        content: url("#{$img-path}/icons/clock_icon.svg");
        display: inline-block;
        width: rem-calc(20);
        height: rem-calc(20);
        margin-right: rem-calc(10);
        position: relative;
        top: rem-calc(3);
      }
		}

		.field-name-field-tags {
      &:before {
        content: url("#{$img-path}/icons/tag_icon.svg");
        display: inline-block;
        width: rem-calc(20);
        height: rem-calc(20);
        margin-right: rem-calc(10);
        position: relative;
        top: rem-calc(3);
      }
			margin-right: 0;

			ul {
				list-style: none;
				margin-left: 0;
        display: inline;

				li {
					display: inline;
					margin-right: rem-calc(5);

					&:after {
						content: ",";
					}

					&:last-child:after {
						content: "";
					}
				}
			}
		}

		.field-group-div {
			background: lighten($Aggdata_blue, 55%);
			padding-left: rem-calc(25);
			padding-top: rem-calc(12);
			padding-bottom: rem-calc(12);
			margin-bottom: rem-calc(20);

			&:after {
				content: "";
				display: table;
				clear: both;
			}
		}

		.body {
			color: $steel;
		}
	}

	&.view-mode-full {
		.field-name-field-news-image img {
			width: 100%;
		}
	}
}

.node-type-webform {
	.form-submit {
		background: $Aggdata_green;

		&:hover {
			background: darken($Aggdata_green, 10%);
		}
	}
}

/******** product display nodes ********/
.node-type-aggdata,
.node-type-aggdata-sublist,
.node-type-aggdata-bundle,
.node-type-freedata {
	#page-title {
		font-size: rem-calc(30);
		line-height: rem-calc(35);
		text-align: center;
    @media #{$medium-up} {
      text-align: left;
    }
	}

  .field-name-field-inline-image.field {
    text-align: center;
    @media #{$medium-up} {
      text-align: left;
      float: left;
      padding-right: rem-calc(15);
      padding-left: rem-calc(15);
    }
  }

	.body.field {
		margin-bottom: rem-calc(35);
    text-align: center;
    padding: 0;
    @media #{$medium-up} {
      text-align: left;
    }

		p, span {
			font-size: rem-calc(18) !important;
			line-height: rem-calc(24) !important;
			color: $steel !important;
		}

		img {
			padding-left: rem-calc(20);
			padding-right: rem-calc(50);
		}
	}

	.product-sub-list {
		margin-bottom: rem-calc(62);
		border: 1px solid $Aggdata_blue;
		padding: rem-calc(19);

		p {
			font-size: rem-calc(18);
			line-height: rem-calc(24);
		}

		p:last-child {
			margin-bottom: 0;
		}
	}

	.field-label {
		font-size: rem-calc(18);
		line-height: rem-calc(21);
		font-weight: bold;
		margin-bottom: rem-calc(10);
	}

	.field-items {
		font-size: rem-calc(16);
		line-height: rem-calc(19);
	}

	.product-details-wrap {
    clear: both;
		margin-bottom: rem-calc(58);

    .product-details {

      > h3 {
        font-size: rem-calc(21);
        line-height: rem-calc(26);
        background-color: $Aggdata_green;
        padding-top: rem-calc(19);
        padding-bottom: rem-calc(15);
        padding-left: rem-calc(18);
        padding-right: rem-calc(18);
        color: $Aggdata_dkblue;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: -(rem-calc(15));
        margin-right: -(rem-calc(15));
        @media #{$medium-up} {
          margin-left: 0;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }
      }

      .left-col {
        background: $snow;
        margin-left: 0;
        padding-left: rem-calc(15);
        padding-right: rem-calc(15);
        padding-bottom: rem-calc(15);
        @media #{$medium-up} {
          margin-left: rem-calc(15);
          margin-right: -(rem-calc(15));
          border-bottom-left-radius: 6px;
        }
      }

      .right-col {
        background: $silver;
        padding-left: rem-calc(15);
        padding-right: rem-calc(15);
        @media #{$medium-up} {
          border-bottom-right-radius: 6px;
        }
        @media #{$small-only} {
          padding-left: 0;
          padding-right: 0;
          .field-name-field-included-fields {
            padding-top: 0;
            padding-bottom: 0;
            .field-items {
              display: none;
              padding: rem-calc(15);
              .field-item {
                margin-left: rem-calc(15);
              }
            }
            .field-label {
              background: $Aggdata_green;
              color: $Aggdata_dkblue;
              padding: rem-calc(15);
              margin: 0;
              cursor: pointer;
              &:after {
                content: "\203A";
                color: $Aggdata_dkblue;
                float: right;
                font-weight: normal;
                font-size: rem-calc(40);
                position: relative;
                top: rem-calc(-2);
              }
            }
          }
        }
      }

      .field {
        padding: rem-calc(5) 0;
      }

      .has-tip, .help-icon {
        width: 18px;
        height: 18px;
        background: url('#{$img-path}/icons/tooltip_icon.svg') no-repeat;
        text-align: left;
        text-indent: -9999px;
        display: inline-block;
        border: none;
        margin-left: 5px;
        &:hover, &.open {
          background: url('#{$img-path}/icons/tooltip_icon_hover.svg') no-repeat;
          border: none;
          cursor: default;
        }
      }
      .qtip-link {
        border: none;
      }
    }

		.product-purchase, .product-purchase-bundle {
			text-align: right;
      @media #{$small-only} {
        background: $white_smoke;
        padding-top: rem-calc(36);
        margin-bottom: rem-calc(30);
      }

			.product-price {
        .original-price {
          color: $red;
          text-decoration: line-through;
          font-size: rem-calc(18);
          @media #{$small-only} {
            padding: 0 0 rem-calc(10) 0;
            border-bottom: 1px solid $base;
          }
        }
        .discounts-wrapper {
          margin-bottom: 20px;
          @media #{$small-only} {
            padding: rem-calc(10) 0;
            border-bottom: 1px solid $base;
          }
          .discounts-price {
            font-size: rem-calc(18);
            color: $tuatara;
          }
        }
        .final-price {
          font-size: rem-calc(42);
          line-height: rem-calc(50);
          font-weight: bold;
          color: $Aggdata_green;
          float: left;
          @media #{$medium-up} {
            float: none;
          }
          @media #{$medium-only} {
            font-size: rem-calc(35);
          }
        }
			}

      #cart-buttons {
        position: relative;
        top: rem-calc(-19);
        margin-bottom: rem-calc(-19);
        button, .button {
          &.buy-now {
            background: $Aggdata_blue;
            color: white;
            &+ span {
              position: relative;
              right: rem-calc(135);
              top: 0;
              color: white;
            }
            &:hover {
              border: 2px solid darken($Aggdata_blue, 10%);
              background: darken($Aggdata_blue, 10%);
            }
          }
          &+ span {
            position: relative;
            right: rem-calc(145);
            bottom: rem-calc(74);
            color: $Aggdata_blue;
          }
          background:white;
          border: 2px solid $Aggdata_blue;
          color: $Aggdata_blue;
          min-width: rem-calc(184);
          padding-left: rem-calc(10);
          padding-right: rem-calc(10);
          &:hover {
            background: $smoke;
          }
        }
        @media #{$medium-up} {
          top: inherit;
          margin-bottom: inherit;
        }
        .download {
          margin-top: rem-calc(15);
          background: $Aggdata_green;
          &:hover {
            background-color: darken($Aggdata_green, 10%);
          }
        }
      }
		}
	}

	.field {
		padding: rem-calc(15) 0;
		color: $steel;
	}

	.field-name-field-included-fields .field-item {
		display: list-item;
		margin-left: rem-calc(16);
		margin-bottom: rem-calc(8);

		font-size: rem-calc(14);
		line-height: rem-calc(18);
	}

	.field-name-field-dataset-category,
	.field-name-field-sic-code {
		.links {
			margin: 0;
			list-style: none;

			li {
				line-height: rem-calc(16);
				margin-bottom: rem-calc(10);

				a {
					font-size: rem-calc(14);
					line-height: rem-calc(16);
				}
			}
		}
	}

	.field-name-field-parent {
		border-bottom: 1px solid $grey;
	}

	.sample,
	.subscribe {
		padding-top: rem-calc(12);
		padding-bottom: rem-calc(12);

		a {
      border-style: solid;
      border-width: 1px;
	    cursor: pointer;
	    font-weight: normal;
	    line-height: normal;
	    position: relative;
	    text-decoration: none;
	    text-align: center;
	    -webkit-appearance: none;
	    display: inline-block;
	    background-color: transparent;
	    border-color: darken($Aggdata_blue, 10%);
	    transition: background-color 300ms ease-out;
	    text-transform: uppercase;
	    border-radius: 6px;
      color: $Aggdata_blue;

	    &:hover {
	    	background-color: $Aggdata_blue;
        color: $white;
	    }

			font-size: rem-calc(16);
			line-height: rem-calc(21);
      padding: rem-calc(5) rem-calc(16) rem-calc(9);
      margin: 0;
		}
	}

	.sample a {
    &:before {
    content: url("#{$img-path}/icons/download_icon.svg");
    display: inline-block;
    width: rem-calc(30);
    height: rem-calc(30);
    margin-right: rem-calc(10);
    position: relative;
    top: rem-calc(9);
    margin-top: rem-calc(-9);
    }
    &:hover:before {
      content: url("#{$img-path}/icons/download_icon_white.svg");
    }
	}

	.subscribe a {
    &:before {
    content: url("#{$img-path}/icons/mail_icon.svg");
    display: inline-block;
    width: rem-calc(30);
    height: rem-calc(30);
    margin-right: rem-calc(10);
    position: relative;
    top: rem-calc(9);
    margin-top: rem-calc(-9);
    }
    &:hover:before {
      content: url("#{$img-path}/icons/mail_icon_white.svg");
    }
	}

	.download-free-dataset {
		padding-top: rem-calc(15);
		padding-bottom: rem-calc(15);

		a {
			font-weight: bold;
		}
	}
}

.node-aggdata-bundle {
  .product-list-bundle {
    table {
      border: none;
      tr.total-records,
      tr.bundled-savings,
      tr.unbundled-price {
        background: transparent;
        border: none;
        td {
          background: transparent;
          border: none;
          &:first-child {
            text-align: right;
          }
          @media #{$small-only} {
            display: none;
            color: $steel;
            font-weight: normal;
            &:first-child {
              text-align: left;
            }
          }
        }
      }
      tr.unbundled-price {
        td:last-child {
          color: $red;
          text-decoration: line-through;
        }
        @media #{$small-only} {
          td:first-child, td:last-child {
            display: block;
          }
          td:last-child {
            text-align: right;
            position: relative;
            top: rem-calc(-44);
            margin-bottom: rem-calc(-44);
            &:before {
              content: "";
            }
          }
        }
      }
      tr.bundled-savings {
        font-weight: bold;
        @media #{$small-only} {
          td:first-child, td:last-child {
            display: block;
            font-weight: bold;
          }
          td:last-child {
            font-weight: bold;
            text-align: right;
            position: relative;
            top: rem-calc(-44);
            margin-bottom: rem-calc(-44);
            &:before {
              content: "";
            }
          }
        }
      }
      tr.total-records {
        @media #{$small-only} {
          td:first-child, td:nth-child(3) {
            display: block;
          }
          td:nth-child(3) {
            text-align: right;
            position: relative;
            top: rem-calc(-44);
            margin-bottom: rem-calc(-44);
            &:before {
              content: "";
            }
          }
        }
      }
      td:last-child, th:last-child {
        white-space: nowrap;
        text-align: right;
        @media #{$small-only} {
          text-align: left;
        }
      }
    }
  }
  .product-details-wrap .product-purchase-bundle {
    @media #{$small-only} {
      background: none;
      padding-top: rem-calc(16);
    }
    #cart-buttons {
      button {
        padding-left: rem-calc(32);
        padding-right: rem-calc(32);
      }
    }
  }
}

.field-name-commerce-price {
  font-size: rem-calc(42);
  line-height: rem-calc(50);
  font-weight: bold;
  color: $Aggdata_green;
}

.field-type-commerce-product-reference {
  button, .button {
    margin-top: rem-calc(15);
    min-width: rem-calc(144);
    background: $Aggdata_blue;
    &:hover {
      background-color: darken($Aggdata_blue, 10%);
    }
  }
}

.node-type-pdf-report .commerce-add-to-cart {
  button, .button {
    &.buy-now {
      background: $Aggdata_blue;
      color: white;
      &:hover {
        border: 2px solid darken($Aggdata_blue, 10%);
        background: darken($Aggdata_blue, 10%);
        color: white;
      }
    }
    background:white;
    border: 2px solid $Aggdata_blue;
    color: $Aggdata_blue;
    min-width: rem-calc(184);
    padding-left: rem-calc(10);
    padding-right: rem-calc(10);
    &:hover {
      background: $smoke;
      color: $Aggdata_blue;
    }
  }
}

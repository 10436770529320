.entity-paragraphs-item {
	.content {
		margin: 0 auto;
		max-width: 68.75rem;
		padding: $column-gutter ($column-gutter/2);
    @media #{$small-only} {
      padding-left: 3rem;
      padding-right: 3rem;
    }

		&::after {
			content: "";
			display: table;
			clear: both;
		}

		.field-name-field-title,
		.field-name-field-content {
			font-family: 'Hind Siliguri', sans-serif;
			text-align: center;
		}
		.field-name-field-title {
			font-size: rem-calc(33);
			line-height: rem-calc(42);
			font-weight: bold;

			&::after {
				content: "";
				display: table;
				clear: both;
			}
		}

		.field-name-field-content {
			font-size: rem-calc(20);
			line-height: rem-calc(28);
		}
	}
}

.invert {
	color: $white;
  p, h1, h2, h3, h4, h5 {
    color: $white;
  }
}

.top-banner {

	.field-name-field-title, h2 {
		padding: rem-calc(16) 0 0;
    @media #{$medium-up} {
      padding-top: rem-calc(35);
    }
    @media #{$large-up} {
      padding: rem-calc(50) rem-calc(110) 0;
    }
	}

	.button-group {
		padding-top: rem-calc(10);

		a.button {
			width: 240px;
			margin-bottom: 0;
      font-weight: bold;
		}

		li:nth-child(2) {
			margin-left: rem-calc(30);
		}

    li {
      @media #{$small-only} {
        display: block;
        &:nth-child(2) {
          margin-left: 0;
        }
        a.button {
          display: block;
          width: auto;
          margin-bottom: rem-calc(10);
        }
      }
    }
	}

	.services {
		margin-top: rem-calc(20);
	}

  .search-data-mobile {
    background: $white;
    padding: 1rem 3rem;
    margin-right: -3rem;
    margin-left: -3rem;
    a {
      border: 1px solid $Aggdata_green;
      border-radius: $global-radius;
      padding: 0.625rem 2rem;
      width: 100%;
      display: block;
      margin: 0 auto;
      text-align: left;
      color: $Aggdata_green;
      text-decoration: none;
      &:active, &:hover, &:focus {
        text-decoration: none;
      }
      span {
        float: right;
      }
    }
    .search-column {
      float: none;
      display: none;
      margin-top: 1rem;
      padding: 0;
      .search-form {
        margin-bottom: 0;
        input[type="text"], button, .button {
          margin-bottom: 0;
        }
      }
    }
  }
}

.why-aggdata {
	.field-name-field-content {
		font-family: 'Hind Siliguri', sans-serif;
		margin-top: rem-calc(60);
		margin-bottom: rem-calc(60);
		color: $steel;

		&::after {
			content: "";
			display: table;
			clear: both;
		}
	}

	.cta {

		h4 {
			margin-top: rem-calc(16);
		}

		p {
			font-family: 'Hind Siliguri', sans-serif;
			padding-top: rem-calc(10);
			font-size: rem-calc(16);
			line-height: rem-calc(21);
		}
	}
}

.middle-banner {
	.field-name-field-content {
		margin-top: rem-calc(45);
		margin-bottom: rem-calc(40);

		.right-fit {
			font-size: rem-calc(45);
			line-height: rem-calc(48);
			padding-bottom: rem-calc(20);
		}

		.button {
			margin-bottom: 0;
		}
	}
}

.stats {
	.field-name-field-content {
		margin-top: rem-calc(80);
		margin-bottom: rem-calc(80);
		color: $steel;

		.small-centered {
			&:after {
				content: "";
				display: table;
				clear: both;
			}
		}
	}

	.cta {
		h4 {
			font-family: 'Hind Siliguri', sans-serif;
			font-size: rem-calc(55);
			line-height: rem-calc(55);
			font-weight: bold;
			margin-bottom: 0;
      text-align: center;
      @media #{$medium-only} {
        font-size: rem-calc(36);
      }
      @media #{$small-only} {
        margin-left: -2.8rem;
        margin-right: -2.8rem;
      }
		}

		p {
			font-family: 'Hind Siliguri', sans-serif;
			padding-top: rem-calc(10);
			font-size: rem-calc(15);
			line-height: rem-calc(27);
		}
	}
}

.customers {
	.field-name-field-content {
		margin-top: rem-calc(35);
		margin-bottom: rem-calc(25);

		p {
			font-size: rem-calc(18);
			line-height: rem-calc(27);
			color: $Aggdata_dkblue;
      @media #{$medium-up} {
        display: inline;
      }
		}
    ul {
      margin-left: 0;
      @media #{$medium-up} {
        display: inline;
      }
      li {
        margin-bottom: rem-calc(25);
        list-style: none;
        @media #{$medium-up} {
          display: inline-block;
          padding-left: rem-calc(40);
        }
      }
    }
	}
}

.contact {
	padding: rem-calc(50) 0;

	.content {
		.field-name-field-title,
		.field-name-field-content {
			color: $white;
		}

		.field-name-field-title {
			font-size: rem-calc(45);
			line-height: rem-calc(50);
			font-weight: bold;
		}

		.field-name-field-content {
			margin-top: rem-calc(20);
			p {
				color: $white;
			}
		}

		.cta {
			h4 {
				color: $white;
				font-size: rem-calc(21);
				line-height: rem-calc(32);
				margin-bottom: 0;
			}

			p {
				font-size: rem-calc(18);
				line-height: rem-calc(32);
			}

			p a {
				color: $white;
			}
		}

		.button-group {
			margin-top: rem-calc(40);

			li:last-child {
				padding-left: 0;
        @media #{$medium-up} {
          padding-left: rem-calc(50);
        }
			}
		}
	}
}

//******************* Product landing page *******************//
.products-top-banner {
	margin-bottom: rem-calc(57);
  @media #{$medium-up} {
    height: 300px;
  }

	.content {
    @media #{$medium-up} {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

		.field-name-field-title  {
			.field-item {
				font-size: rem-calc(45);
				line-height: rem-calc(50);
				color: $white;
				margin-bottom: rem-calc(18);
				font-weight: 400;
			}
		}

		.field-name-field-content {
			.field-item,
			.field-item p {
				font-size: rem-calc(22);
				line-height: rem-calc(24);
				color: $white;
			}
		}
	}
}

.intro {
	.field-name-field-title .field-item {
		font-size: rem-calc(45);
		line-height: rem-calc(50);
		font-weight: 400;
		margin-bottom: rem-calc(37);
	}

	.field-name-field-content p {
		text-align: center;
		display: inline-block;
    @media #{$medium-up} {
      max-width: percentage(772/1100);
    }
	}
}

.products-cta {
	margin-bottom: rem-calc(50);

	.cta {
		color: $white;
		padding: 0;
		padding-bottom: rem-calc(36);
		background-color: $white;
		border: 2px solid $dksmoke;
		border-top: 0;
		position: relative;
		margin-top: rem-calc(54);

		h3 {
			width: 100%;
			background-color: $Aggdata_blue;
			margin: 0;
			padding: rem-calc(35) rem-calc(15);
			font-size: rem-calc(30);
			line-height: rem-calc(34);
			font-weight: bold;
			text-transform: uppercase;
		}

		h4 {
			font-size: rem-calc(60);
			line-height: rem-calc(65);
			font-weight: 600;
			color: $Aggdata_blue;
			margin-top: rem-calc(27);
			margin-bottom: rem-calc(37);
			text-transform: uppercase;

			span {
				font-size: rem-calc(18);
				line-height: rem-calc(34);
				color: $Aggdata_dkblue;
        font-family: Lato, sans-serif;
				font-style: italic;
				font-weight: 300;
				text-transform: capitalize;
			}
		}

		p {
			font-size: rem-calc(18);
			line-height: rem-calc(24);
			width: percentage(221/314);
			font-weight: 400;
			color: $steel;
			text-align: center;
			display: inline-block;
			margin: 0;
			padding: 0;
		}

		.button {
			font-size: rem-calc(18);
			line-height: rem-calc(25);
			font-weight: bold;
			width: percentage(250/314);
			position: absolute;
			bottom: -25px;
		  left: 0;
		  right: 0;
		  display: block;
		  margin: 0 auto;
		}

		&.single,
		&.custom {
      @media #{$medium-up} {
        width: percentage(314/1100);
      }
		}

		&.single {
			margin-right: rem-calc(32);
      margin-bottom: rem-calc(54);
			h4 {
				line-height: rem-calc(45);
			}
		}

		&.premium {
			padding-bottom: rem-calc(95);
			margin-top: 0;
      @media #{$medium-up} {
        width: percentage(406/1100);
      }

			h3 {
				font-size: rem-calc(38);
				line-height: rem-calc(43);
			}

			h4 {
				font-size: rem-calc(50);
				line-height: rem-calc(48);
				color: $Aggdata_green;
				margin-top: rem-calc(76);
				margin-bottom: rem-calc(62);
			}

			p {
				width: percentage(220/408);
			}

			.button {
				width: percentage(250/480);
				background-color: $Aggdata_green;

				&:hover {
					background-color: darken($Aggdata_green, 10%);
				}
			}
		}
	}

	.premium {
		h3 {
			background-color: $Aggdata_green;
		}
	}

	h3, h4 {
		color: $white;
	}

	h4 {
		font-size: rem-calc(45);
		font-weight: bold;

		span {
			font-size: rem-calc(15);
			display: block;
		}
	}
}

.not-front {
	.middle-banner {
		margin-bottom: rem-calc(84);

		.right-fit {
			color: $Aggdata_dkblue;
		}
	}
}


.bundles,
.free {
	.cta {
		background-color: $silver;
		padding: 0;
    margin-bottom: rem-calc(30);

		&:after {
			content: "";
			display: table;
			clear: both;
		}

		h3 {
			font-size: rem-calc(30);
			line-height: rem-calc(35);
			color: $white;
			padding: rem-calc(22) rem-calc(15);
			margin-bottom: rem-calc(40);
      margin-top: 0;
			background-color: $Aggdata_dkblue;
			text-align: center;
		}

		p {
			font-size: rem-calc(18);
			line-height: rem-calc(24);
			width: percentage(240/347);
			display: inline-block;
			text-align: center;
		}

		.button {
			background-color: $Aggdata_blue;

			&:hover {
				background-color: darken($Aggdata_blue, 10%);
			}
		}
	}

	.info {
		padding-top: 0;

		h2 {
			text-align: left;
		}
		
		h3 {
			font-size: rem-calc(30);
			line-height: rem-calc(40);
			margin-bottom: rem-calc(25);
		}

		h3,
		p,
		ul {
			text-align: left;
		}

		p,
		ul {
			font-size: rem-calc(18);
			line-height: rem-calc(24);
			color: $steel;
		}

		ul {
			margin-left: rem-calc(40);

			li {
				margin-bottom: rem-calc(16);
			}
		}
	}
}

.free {
	.cta {
		h3 {
			background-color: $Aggdata_dkblue;
		}
	}
}

/********** Premium Page ***********/
.main-content {
	p {
		text-align: left;
	}
}

.whitepaper {
	.field-name-field-content {
    margin-bottom: rem-calc(30);
		position: relative;
		border: 6px solid $Aggdata_blue;
		padding: percentage(34/1100);
    @media #{$medium-up} {
      float: right;
		  width: percentage(431/1100);
		  margin-right: rem-calc(150);
    }

		.field-item {
			text-align: left;
			color: $steel;

			p {
				font-size: rem-calc(18);
				line-height: rem-calc(24);
			}

			ul {
				margin-left: 0;

				li {
					font-size: rem-calc(18);
					line-height: rem-calc(24);
					display: block;
					padding-top: rem-calc(8);
					padding-bottom: rem-calc(8);
          padding-left: rem-calc(48);
          &:before {
            content: url("#{$img-path}/icons/blue_check_icon.svg");
            display: inline-block;
            width: rem-calc(40);
            height: rem-calc(40);
            margin-left: rem-calc(-48);
            margin-right: rem-calc(4);
            position: relative;
            top: rem-calc(20);
          }

					span {
						padding-top: rem-calc(8);
						padding-bottom: rem-calc(8);
					}

					&:not(:last-child) span {
						border-bottom: 1px solid $grey;
					}
				}
			}
		}
	}
	.field-name-field-block {
    margin-bottom: rem-calc(30);
		border: 1px solid #cdcdcd;
    @media #{$medium-up} {
      float: left;
      width: percentage(348/1100);
      margin-left: rem-calc(100);
    }

		.field-items {
			background: $ghost;

			> .field-item {
				padding: rem-calc(10) percentage(28/347);
			}

			&:before {
				content: "Premium";
				display: block;
				background: $silver;
				color: $Aggdata_blue;
				text-align: center;
				font-size: rem-calc(20);
				line-height: rem-calc(27);
				font-weight: bold;
				padding: rem-calc(22) percentage(130/1100);
				border-bottom: 1px solid $grey;
			}

			.description {
				font-size: rem-calc(14);
				line-height: rem-calc(20);
				color: $steel;
				padding-left: rem-calc(5);
				text-align: left;
			}

			.form-submit {
				background: $Aggdata_blue;
				width: 100%;
				color: $white;

				&:hover {
					background: darken($Aggdata_blue, 10%);
				}
			}
		}
	}
}

.future-retail-openings-paragraph {
  min-height: 258px;
  max-width: 68.75rem;
  margin: 0 auto;
  position: relative;
  background-position-x: 80%;

  .content {
    padding: 17px 32px;
  }
  h2 {
    text-align: left;
    margin-top: 0;
    margin-bottom: 5px;
    color: #1464c0;
    span.highlight {
      font-weight: bold;
    }
    @media #{$medium-up} {
      font-size: 59px;
      margin-bottom: 17px;
    }
  }
  .block-webform {

    form {
      > div {
        box-shadow: 0px 7px 15px 6px rgba(0, 0, 0, 0.1);
        display: inline-block;
        background: #fff;
      }
      .webform-component-email {
        display: inline-block;
        margin: 0;

        input {
          box-shadow: inset 0px 0px 0px 0px #ffffff;
          background: #fff;
          border: 0;
          outline: 0;
          border-radius: 0;
          padding: 18px 20px 0;
					margin: 0;
					margin-top: -4px!important;
          font: inherit;
          line-height: 1rem;
          font-size: 20px;
          font-weight: bold;
          &::placeholder {
            color: $Aggdata_blue;
          }
          @media #{$small-only} {
            width: 200px;
          }
        }
      }
      .button {
        display: inline-block;
        border: 0;
        outline: 0;
        border-radius: 0;
        line-height: 1rem;
        margin: 0;
        font-size: 20px;
        padding: 17px 28px;
        font-weight: bold;
        background-color: $Aggdata_green;
        &:after {
          content: '\f0a9';
          font-family: FontAwesome;
          padding-left: 10px;
          font-size: 24px;
        }
        &:hover {
          background-color: $Aggdata_dkgreen;
        }
      }
    }
  }
}

.find-out-how {
	border: 1px solid $silver;
	background: $silver;
	width: 100%;
	padding: rem-calc(20);
	border-radius: 6px;
	margin-bottom: rem-calc(40);

	img {
		max-width: 100%;
		border: 5px solid $white;
		display: block;
		margin: 0 auto;
		margin-bottom: rem-calc(38);
	}

	h3 {
		font-size: rem-calc(25);
		line-height: rem-calc(30);
		text-align: center;
		margin-bottom: rem-calc(25);
	}

	p {
		font-size: rem-calc(18);
		line-height: rem-calc(24);
		text-align: center;
		margin-bottom: rem-calc(30);
		color: $steel;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.button {
		margin-bottom: 0;
	}
}

#download-catalogue {
	border: 1px solid $grey;
	border-radius: 6px;
	background-color: $white;
	text-align: center;

	.buttons {
		padding: rem-calc(220) 0 rem-calc(20) 0;
		background: url("#{$img-path}/our_catalog.gif") center 20% no-repeat;

		a {
	    border-style: solid;
	    border-width: 0;
	    cursor: pointer;
	    font-weight: normal;
	    line-height: normal;
	    margin: 0 0 1.25rem;
	    position: relative;
	    text-decoration: none;
	    text-align: center;
	    -webkit-appearance: none;
	    border-radius: 0;
	    display: inline-block;
	    padding-top: 1rem;
	    padding-right: 2rem;
	    padding-bottom: 1.0625rem;
	    padding-left: 2rem;
	    font-size: 1rem;
	    background-color: $Aggdata_blue;
	    border-color: darken($Aggdata_blue, 10%);
	    color: $white;
	    transition: background-color 300ms ease-out;

	    border-radius: 6px;

	    &:hover {
	    	background: darken($Aggdata_blue, 10%);
	    }
		}
	}
}


/** Related Data Sets, Recently Viewed Block **/
.block-aggdata-product-display-related-data-block,
.block-views-data-set-related-block  {
	h2 {
		font-size: rem-calc(25);
		line-height: rem-calc(28);
	}

	a {
		color: lighten($Aggdata_blue, 10%);
	}
}

.block-aggdata-product-display-related-data-block {
	h2 {
    &:before {
      content: url("#{$img-path}/icons/files_small_icon.svg");
      display: inline-block;
      width: rem-calc(20);
      height: rem-calc(20);
      margin-right: rem-calc(10);
    }
	}
}

.block-views-data-set-related-block {
	h2 {
    &:before {
      content: url("#{$img-path}/icons/eye_icon.svg");
      display: inline-block;
      width: rem-calc(20);
      height: rem-calc(20);
      margin-right: rem-calc(10);
    }
	}
}


/** Sidebar Menu Block **/
.block-aggdata-general-dataset-category-menus {
	h2 {
		font-size: rem-calc(21);
	  line-height: rem-calc(36);
    .expand {
			display: block;
			text-indent: 100%;
			white-space: nowrap;
			overflow: hidden;
			background: url("#{$img-path}/icons/plus_icon.svg") no-repeat left center;
			width: 33px;
			height: 33px;
			margin-right: rem-calc(18);
			float: left;
      @media #{$medium-up} {
        display: none; 
      }

			&.active {
				background: url("#{$img-path}/icons/minus_icon.svg") no-repeat left center;
			}
		}
	}

	> .menu {
    display: none;
    @media #{$medium-up} {
      display: block;
    }
		list-style: none;
		margin-left: 0;

		li {
			margin-bottom: rem-calc(16);

			a {
				font-size: rem-calc(16);
				line-height: rem-calc(21);
				color: $Aggdata_blue;
			}
		}
	}

	.active-trail {
		font-weight: bold;

		.menu {
			margin-top: rem-calc(16);
			list-style: none;
			font-weight: normal;
		}
	}
}

.block-aggdata-sic-code-block form {
  margin-bottom: rem-calc(45);
}
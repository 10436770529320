//****** Benefits page ********/
.section-why-us {
	.cta {
		padding-left: rem-calc(100);
		border-bottom: 1px solid lighten($steel, 30%);
		padding-top: rem-calc(30);
		padding-bottom: rem-calc(30);
		margin-top: rem-calc(20);

		h3 {
			font-size: rem-calc(26);
			line-height: rem-calc(31);
			text-transform: uppercase;
      padding-top: rem-calc(20);
		}

		> p {
			padding-top: rem-calc(20);
      @media #{$small-only} {
        margin-left: rem-calc(-100);
      }
		}

		&.focus:before {
			background: url("#{$img-path}/icons/focus_icon.svg");
      content: " ";
      width: 80px;
      height: 80px;
      position: absolute;
      left: $column-gutter/2;
		}

		&.quality:before {
      background: url("#{$img-path}/icons/quality_icon.svg");
      content: " ";
      width: 80px;
      height: 80px;
      position: absolute;
      left: $column-gutter/2;
		}

		&.support:before {
			background: url("#{$img-path}/icons/support_icon.svg");
      content: " ";
      width: 80px;
      height: 80px;
      position: absolute;
      left: $column-gutter/2;
		}

		&.range:before {
			background: url("#{$img-path}/icons/variety_icon.svg");
      content: " ";
      width: 80px;
      height: 80px;
      position: absolute;
      left: $column-gutter/2;
		}

		&.flexibility:before {
			background: url("#{$img-path}/icons/files_icon.svg");
      content: " ";
      width: 80px;
      height: 80px;
      position: absolute;
      left: $column-gutter/2;
		}
    &.flexibility {
			border: 0; 
    }
	}
}

.page-faq-page {
	.faq {
		.faq-question-answer {
			padding-top: rem-calc(20);
			padding-bottom: rem-calc(20);
			border-bottom: 1px solid $grey;

      &:last-child {
        border-bottom: none;
      }

			.faq-question {
				display: block;
				overflow: hidden;
        cursor: pointer;
        span a:before {
          content: url("#{$img-path}/icons/plus_icon.svg");
          display: inline-block;
          width: rem-calc(35);
          height: rem-calc(35);
          margin-right: rem-calc(10);
          position: relative;
          top: rem-calc(9);
        }

				&.faq-qa-visible {
          span a:before {
            content: url("#{$img-path}/icons/minus_icon.svg");
          }
				}

				a {
					display: block;
					font-size: rem-calc(21);
					line-height: rem-calc(30);
					vertical-align: middle;
					color: $steel;

					&:hover {
						color: $Aggdata_blue;
					}
				}
			}

			.faq-answer {
				margin-top: rem-calc(20);
			}
		}
	}
}


/******** Categories Page ********/
.section-data {
	.data-set-buttons {
		.button {
			background: $Aggdata_blue;

			&:hover {
				background: darken($Aggdata_blue, 10%);
			}
		}
	}
}

.page-user-login {
	.description {
		display: none;
	}

	.button-group {
		li:first-child {
			display: none;
		}
	}
}

/*********** Check out page *********/
.page-checkout {
	fieldset {
		border: 0;
		margin-bottom: rem-calc(50);
    // Mash account & billing information fieldset panes.
    &.account{
      margin-bottom: 0;
      padding-bottom: 0;
    }
    &.customer_profile_billing {
      margin-top: 0;
      padding-top: 0;
    }

		legend {
			font-size: rem-calc(25);
			line-height: rem-calc(30);
			text-transform: capitalize;
		}

    fieldset {
      padding: 0;
      margin-top: rem-calc(36);
    }
	}

	.commerce_payment {
		margin-bottom: 0;
	}

	.checkout-buttons {
		padding-left: 0;
	}

	.commerce-order-handler-area-order-total .commerce-price-formatted-components {
		width: 40%;
	}

	table {
		thead,
		tbody {
			tr td,
			tr th {
				padding: rem-calc(10);
			}
		}
	}

	.commerce-paypal-icons {
		display: none;
	}

/*	.l-messages {
		display: none;
	}*/

  button.checkout-continue {
    @media #{$small-only} {
      width: 100%;
    }
  }

  .form-item-commerce-payment-payment-method {
    span.icons {
      @media #{$small-only} {
        display: block;
      }
    }
  }

  .commerce-paypal-icon {
    top: 0; 
  }

  iframe#ccframe {
    height: 160px;
  }
}

/*********** Profile *********/
.profile {
  .field {
    .field-label {
      display: inline-block;
      font-size: rem-calc(18);
    }
    .field-items {
      display: inline-block;
    }
  }
  h3 {
    margin-top: rem-calc(30);
  }
}

/*********** Simple PDF *********/
.node-simple-pdf {
	&.view-mode-full {
		.field-name-field-pdf {
			object {
				width: 100%;
			}
		}
	}
}

.field-name-field-region .field-items .field-item{display: inline-block;}.field-name-field-region .field-items .field-item:not(:last-child):after { content: ","; margin-right: 5px;}
.button,
.main .button,
button {
  font-size: rem-calc(16.7);
  line-height: rem-calc(25);
  font-weight: bold;
  letter-spacing: .8px;
	border-radius: 5px;
	color: $white;

	&:hover {
		color: $white;
		text-decoration: none;
	}

	&.transparent {
		background-color: transparent;
		border: 1px solid $white;
		color: $white;

		&:hover {
			background-color: rgba(255,255,255, 0.3);
			color: $white;
			text-decoration: none;
		}
	}

	&.arrow {
		&::after {
			content: ">>";
			display: inline-block;
			padding-left: rem-calc(10);
			font-size: rem-calc(16);
		}
	}

	&.green {
		background-color: $Aggdata_green;
		color: $Aggdata_dkblue;

		&.border {
			border-bottom: 5px solid rgba(0, 144, 191, 0.4) !important;
		}

		&:hover {
			background-color: darken($Aggdata_green, 10%);
			color: darken($Aggdata_dkblue, 10%);
			text-decoration: none;
		}
	}

	&.blue {
		background-color: $Aggdata_blue;
		color: $white;

		&.border {
			border-bottom: 5px solid rgba(0, 144, 191, 0.4) !important;
		}

		&:hover {
			background-color: darken($Aggdata_blue, 10%);
			color: $white;
			text-decoration: none;
		}
	}

	&.shadow {
		-webkit-box-shadow: 0px 5px 0px 0px rgba(0,0,0,0.14);
		-moz-box-shadow: 0px 5px 0px 0px rgba(0,0,0,0.14);
		box-shadow: 0px 5px 0px 0px rgba(0,0,0,0.14);
	}

  &.secondary {
    color: $oil;
    &.active {
      background: $iron;
    }
  }
}

// Overwirting zurb default;
.button-group {
  margin-bottom: rem-calc(30);
  > li:first-child .button.transparent {
    border: 1px solid $white;
  }
  > li {
    margin: 0;
    > button,
    .button {
      border-left: 0;
      font-weight: normal;
    }
  }
}

.l-main .sidebar-second {
	.button {
		padding: rem-calc(10) rem-calc(20);
	}
}

.product-purchase {
  .commerce-add-to-cart {
    .form-submit {
      background-color: $Aggdata_blue;

      &:hover {
        background-color: darken($Aggdata_blue, 10%);
      }
    }
  }
}

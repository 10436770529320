.view-testimonial-slider {
  .slides {
    .views-field-body {
      color: $white;
      @media #{$medium-up} {
        width: 99.99%; //818/1100
        padding-left: rem-calc(16);
        padding-right: rem-calc(16);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .field-content {
        padding-top: rem-calc(40);
      }
    }
  }

  .flexslider {
    background: transparent;
    border: 0;
    margin-top: rem-calc(20);
    @media #{$medium-up} {
      margin: rem-calc(50) 0;
    }

    &:before {
      content: '';
      display: block;
      width: 181px; //181/818
      height: 133px;
      position: absolute;
      background: url('#{$img-path}/icons/quote_open.png') no-repeat top center;
      left: 0;
      top: auto;
      @media #{$medium-up} {
        top: -30px;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 121px; //121/818
      height: 88px;
      position: absolute;
      right: 0;
      bottom: -30px;
      @media #{$medium-up} {
        background: url('#{$img-path}/icons/quote_close.png') no-repeat top
          center;
      }
    }
  }

  .flex-control-paging {
    li a {
      border: 1px solid $white;
      background: transparent;

      &.flex-active {
        background: $white;
      }
    }
  }
}

.view-news {
  .views-exposed-form {
    .views-submit-button {
      padding-top: 0;
      .form-submit {
        margin-top: 0;
      }
    }
  }

  .views-row-first {
    .group-left {
      width: 100%;
      float: right;

      .field-name-field-news-image img {
        width: 100%;
        border: 5px solid $white;
        display: block;
        margin: 0 auto;
        margin-bottom: rem-calc(10);
      }
    }

    .group-right {
      width: 100%;
      float: left;
      padding-left: 0;
    }

    .field-group-div {
      padding-left: rem-calc(25);
      padding-top: rem-calc(12);
      padding-bottom: rem-calc(12);
      margin-bottom: rem-calc(20);

      &:after {
        content: '';
        display: table;
        clear: both;
      }
    }
  }

  @media #{$small-only} {
    .views-row {
      .group-left {
        width: 100%;
        float: right;

        .field-name-field-news-image img {
          width: 100%;
          border: 5px solid $white;
          display: block;
          margin: 0 auto;
          margin-bottom: rem-calc(10);
        }
      }

      .group-right {
        width: 100%;
        float: left;
        padding-left: 0;
      }

      .field-group-div {
        padding-left: rem-calc(25);
        padding-top: rem-calc(12);
        padding-bottom: rem-calc(12);
        margin-bottom: rem-calc(20);

        &:after {
          content: '';
          display: table;
          clear: both;
        }
      }
    }
  }
}

.view {
  .pagination.pager {
    li {
      margin-left: rem-calc(10);

      a {
        font-size: rem-calc(15);
      }
    }
  }
}

.view-dataset-root,
.view-sic-code-root {
  .view-header {
    h2 {
      font-size: rem-calc(25);
      line-height: rem-calc(30);
      color: $Aggdata_dkblue;
    }
  }

  .item-list {
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);
    border-bottom: 1px solid $grey;

    .expand {
      display: inline-block;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      background: url('#{$img-path}/icons/plus_icon.svg') no-repeat left center;
      width: 33px;
      height: 33px;
      margin-right: rem-calc(18);

      &.active {
        background: url('#{$img-path}/icons/minus_icon.svg') no-repeat left
          center;
      }
    }

    h3 {
      margin-bottom: 0;

      a {
        display: inline-block;
        font-size: rem-calc(21);
        line-height: rem-calc(30);
        color: $steel;
        vertical-align: middle;
      }
    }
  }
}

.view-dataset-root.view-display-id-block .item-list ul,
.block-aggdata-sic-code-block-sic-code-taxonomy-block
  .view-sic-code-root
  .item-list
  ul {
  display: none;
  list-style: none;
  padding-left: rem-calc(32);
  padding-top: rem-calc(22);
}

.section-freedata-category {
  .view-aggdata-taxonomy-term-pages {
    .views-field-commerce-price,
    .views-field-nothing {
      display: none;
    }
  }
}

.view-testimonials {
  .views-row {
    border-bottom: 1px solid lighten($steel, 30%);
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(30);
    margin-top: rem-calc(20);

    &.views-row-last {
      border-bottom: none;
    }

    h3 {
      text-align: right;
      font-size: rem-calc(23);
      line-height: rem-calc(28);
    }

    .testimonial-content {
      &:after {
        content: '';
        display: table;
        clear: both;
      }

      .name {
        font-size: rem-calc(14);
        line-height: rem-calc(32);
        display: block;
        text-align: right;
        color: $Aggdata_blue;
      }

      > .field-content {
        margin-top: rem-calc(20);

        p {
          font-family: 'Hind Siliguri', sans-serif;
          font-size: rem-calc(21);
          line-height: rem-calc(30);
          color: $Aggdata_dkblue;

          &:before {
            content: '';
            display: inline-block;
            width: 67px;
            height: 49px;
            background: url('#{$img-path}/icons/quote_open_icon.png') no-repeat
              left center;
            margin-right: rem-calc(20);
            margin-left: rem-calc(-20);
          }

          &:after {
            content: '';
            display: inline-block;
            width: 37px;
            height: 27px;
            background: url('#{$img-path}/icons/quote_close_icon.png') no-repeat;
            margin-left: rem-calc(20);
            margin-bottom: rem-calc(-11);
          }
        }
      }
    }
  }
}

.view-careers {
  .views-row {
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);
    border-bottom: 1px solid $grey;

    &.views-row-last {
      border-bottom: none;
    }

    .views-accordion-header {
      background: none;
      border: 0;
      white-space: nowrap;
      display: block;
      padding-left: 0;

      span {
        display: inline-block;
      }

      .ui-icon {
        background: transparent url('#{$img-path}/icons/plus_icon.svg')
          no-repeat left center;
        width: rem-calc(40);
        height: rem-calc(40);
        position: relative;
        top: rem-calc(12);
      }

      &.ui-accordion-header-active .ui-icon {
        background: url('#{$img-path}/icons/minus_icon.svg') no-repeat left
          center;
      }
    }

    .field-content {
      margin-left: rem-calc(18);
    }

    .ui-accordion-content {
      border: none;
    }
  }
}

.view-free-data-categories.view-display-id-block {
  ul {
    list-style: none;
    margin-left: 0;

    li {
      display: inline-block;
      margin-left: 1rem;
    }

    li:first-child {
      margin-left: 0;
    }
  }

  .views-row {
    .field-content a {
      border-style: solid;
      border-width: 0;
      cursor: pointer;
      font-weight: normal;
      line-height: normal;
      margin: 0 0 1.25rem;
      position: relative;
      text-decoration: none;
      text-align: center;
      -webkit-appearance: none;
      border-radius: 0;
      display: inline-block;
      padding-top: 1rem;
      padding-right: 1rem;
      padding-bottom: 1.0625rem;
      padding-left: 1rem;
      font-size: 1rem;
      background-color: $Aggdata_blue;
      border-color: darken($Aggdata_blue, 10%);
      color: $white;
      transition: background-color 300ms ease-out;
      text-transform: uppercase;
      border-radius: 6px;

      &:hover {
        background: darken($Aggdata_blue, 10%);
      }
    }
  }
}

.view-user-licensed-files-names {
  .views-table {
    margin-bottom: rem-calc(90);

    caption {
      .file-header {
        text-align: left;
        float: left;
      }

      .file-link {
        float: right;
      }
    }
  }
}

.view-aggdata-taxonomy-term-pages .attachment .view-display-id-attachment_3,
.view-full-dataset-list .attachment .view-display-id-attachment_1 {
  margin-bottom: rem-calc(20);

  a.active {
    background: $silver;
    padding: rem-calc(1) rem-calc(5);
  }

  span {
    @media #{$small-only} {
      display: inline-block;
      visibility: collapse;

      a {
        visibility: visible;
        padding: rem-calc(12) rem-calc(20);
        border-radius: 6px;
        border: 1px solid $silver;
        display: block;
        width: 50px;

        &.active {
          padding: rem-calc(12) rem-calc(20);
        }
      }
    }
  }
}

.view-commerce-cart-form {
  .paypal-ec-order-form,
  #amazon_lpa_cart_pay {
    display: none;
  }

  button#edit-checkout {
    float: right;
  }
}

.view-commerce-cart-form,
.view-commerce-cart-summary {
  table tbody {
    tr.pastpurchase {
      .views-field-commerce-price {
        color: $steel;
      }
    }
  }

  @media #{$small-only} {
    .commerce-order-handler-area-order-total
      .commerce-price-formatted-components {
      width: 100%;

      td {
        width: 100% !important;
      }
    }
  }
}

.view-watch-list {
  @media #{$small-only} {
    .views-field-ops:before {
      content: url('#{$img-path}/icons/mail_icon.svg') !important;
      width: 28px !important;
    }

    .flag-wrapper {
      top: -7px;
    }
  }
}

.view-user-licensed-files-names {
  .view-header {
    form {
      border: 1px solid #9abd73;
      display: inline-block;
      margin: rem-calc(5 0);
      padding: rem-calc(10 30);
      position: relative;
      width: 100%;

      @media (max-width: rem-calc(724)) {
        text-align: center;
      }

      span {
        color: #9abd73;
        display: inline-block;
        margin-top: rem-calc(10);

        @media (min-width: rem-calc(725)) {
          position: absolute;
          top: 50%;
          margin-top: 0;
          transform: translateY(-50%);
        }
      }

      button {
        font-size: rem-calc(14);
        margin: rem-calc(10 0);
        padding: rem-calc(10 15);

        @media (min-width: rem-calc(725)) {
          float: right;
        }
      }
    }
  }
}

.qtip-link {
  color: #666;
}

// Fixing admin menu font size inconstant issue.
#admin-menu-wrapper, 
#admin-menu-wrapper ul {
	font-size: rem-calc(11);
}

// Fixing panel selection layout break issue.
.ctools-modal-content {
	#modal-content {
		.panels-add-content-modal > .panels-section-columns {
			float: none;
		}
	}
}
#recon-banner {
  background: url('/sites/all/themes/aggdata_zurb/images/AD_ExpoBanner-2019.jpg') center center no-repeat;
  background-size: cover;
  height: 380px;
  position: relative;
  width: 100%;
  body:not(.front) & {
    margin-top: -30px;
  }
  #recon-banner-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    #recon-banner-logo {
      text-align: center;
      img {
        height: auto;
        width: 100px;
      }
    }
    #recon-banner-title {
      color: #fff;
      font-size: 4rem;
      letter-spacing: 2px;
      line-height: 3rem;
      text-align: center;
      text-transform: uppercase;
    }
    #recon-banner-booth {
      color: #9fd542;
      font-size: 1.5rem;
      text-align: center;
      text-transform: uppercase;
      span {
        display: inline-block;
        border-bottom: 1px solid #e4e3e6;
        margin: 5px 10px;
        width: 50px;
      }
    }
    #recon-banner-button {
      margin: 0;
      text-align: center;
      .button {
        color: #0b1f50;
        margin: 0;
        &:before {
          font-family: 'FontAwesome';
          content: '\f138';
          letter-spacing: 0.4px;
          padding-right: 10px;
          position: relative;
          font-size: 110%;
        }
      }
    }
  }
}

.node-webform.view-mode-colorbox {
  .node-title,
  .links {
    display: none;
  }
  .webform-client-form {
    background: url('/sites/all/themes/aggdata_zurb/images/booth-map.png') right bottom no-repeat;
    padding-right: 48%;
    position: relative;
  }
  h2 {
    color: #0b1f50;
    font-size: 2.7rem;
    margin-bottom: 1rem;
  }
  p {
    color: #737373;
    line-height: 1.3;
  }
  .form-item input {
    border: 1px solid #3c90f7;
    border-radius: 0;
    max-width: none;
    box-shadow: none;
    color: #3c90f7;
    font-size: 1.4rem;
    padding: 1.5rem 1rem;
  }
  .form-item-url {
    display: none;
  }
  .webform-component--download {
    background-color: #9ed342;
    bottom: 12px;
    display: inline-block;
    margin: 0;
    position: absolute;
    right: 9px;
    height: 20px;
    width: 20px;
    a {
      color: #fff;
      display: block;
      text-align: center;
      line-height: 1.5rem;
      height: 100%;
      width: 100%;
    }
  }
  .webform-submit {
    background-color: #3c90f7;
    font-size: 1.4rem;
    width: 100%;
    &:before {
      font-family: 'FontAwesome';
      content: '\f0a9';
      padding-right: 10px;
      position: relative;
      font-size: 90%;
    }
  }
}

#recon-confirm {
  h2 {
    font-size: 3rem;
  }
  #recon-confirm-text {
    color: #3c90f7;
    font-size: 1.8rem;
    line-height: 1.2;
  }
  #recon-confirm-close {
    margin: 0;
    a {
      border-radius: 0;
      font-size: 1.5rem;
      margin: 0 0 0.5rem;
      font-weight: normal;
      padding: 0.7rem 1.2rem;
    }
  }
  #recon-confirm-download a {
    display: inline-block;
    &:before {
      font-family: 'FontAwesome';
      content: '\f019';
      padding-right: 5px;
      position: relative;
      font-size: 90%;
    }
  }
  #recon-confirm-contact {
    color: #737373;
    a {
      color: #737373;
      text-decoration: underline;
    }
  }
}

.cboxIframe {
  display: none !important; // During the strange instances that the iframe is loaded, let's stop it from displaying.
}
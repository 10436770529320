// Non-modular or client styles for the .l-content region.
body {
	border-top: 7px solid $Aggdata_green;
}

.main {
	background-color: $white;
  margin-bottom: rem-calc(30);

  aside {
    padding-left: 0;
    padding-right: 0;
  }
}

.node-type-paragraph-page .main {
  margin-bottom: 0;
}

.l-main {
	margin-top: 0;
	margin-bottom: rem-calc(30);
	position: relative;
}

.aggdata_divider {
	border-bottom: 1px solid $dksmoke;
	-webkit-box-shadow: 0px 22px 96px -55px rgba(0,0,0,0.41);
	-moz-box-shadow: 0px 22px 96px -55px rgba(0,0,0,0.41);
	box-shadow: 0px 22px 96px -55px rgba(0,0,0,0.41);
  margin-bottom: rem-calc(30);
}

.node-type-paragraph-page .l-main {
	margin-bottom: 0;
}

body.page-taxonomy.section-tags {
	.l-main {
		#page-title {
			display: none;
		}
	}
}

.l-triptych {
  > .columns {
    margin-bottom: rem-calc(15);
    margin-top: rem-calc(15);
    padding-top: rem-calc(15);
    border-top: 1px solid $silver;
  }
}

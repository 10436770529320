.search-results {
  .search-result {
    margin-bottom: 50px;

    h3 {
      margin-bottom: rem-calc(8);
      @media #{$medium-up} {
        margin-bottom: rem-calc(32);
      }
      @media #{$medium-only} {
        max-width: 50%;
      }
    }

    .commerce-add-to-cart {
      margin-bottom: 10px;
      @media #{$medium-up} {
        float: right;
        margin-left: 20px;
        margin-top: -65px;
        margin-bottom: 0;
      }

      .price {
        color: $Aggdata_green;
        font-size: rem-calc(20);
        font-weight: bold;
        margin-right: 10px;
      }

      button, .button {
        font-size: rem-calc(13);
        line-item: rem-calc(20);
        padding: 10px 20px;
        margin-bottom: 0;
        &.buy-now {
          background: $Aggdata_blue;
          color: white;
          &:hover {
            border: 2px solid darken($Aggdata_blue, 10%);
            background: darken($Aggdata_blue, 10%);
            color: white;
          }
        }
        background:white;
        border: 2px solid $Aggdata_blue;
        color: $Aggdata_blue;
        &:hover {
          background: $smoke;
          color: $Aggdata_blue;
        }
      }
    }
  }
}

.search-checkout {
  margin-left: 20px;
}
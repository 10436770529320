.node-type-dynamic-page {
  border-top: none;
  background-color: #f8f8f8;

  .main {
    background-color: #f8f8f8;

    .navbar {
      padding-top: 20px;

      .container {
        @media #{$xlarge-up} {
          margin-left: -200px;
          margin-right: -200px;
        }
      }
      .navbar-header {
        .logo-wrapper {
          display: inline-block;
          width: 180px;
          max-width: 100%;
        }
        .slogan-wrapper {
          margin-left: 10px;
          display: inline-block;
          color: $Aggdata_dkgreen;
          font-weight: 600;
          font-style: italic;
          font-size: rem-calc(14);
        }
        @media #{$small-only} {
          text-align: center;
        }
      }
      .navbar-right {
        .phone {
          background: $Aggdata_blue;
          display: inline-block;
          border-radius: 4px;
          padding: 10px;
          color: white;
          font-size: rem-calc(13);
        }
        @media #{$small-only} {
          text-align: center !important;
          margin-top: 5px;
        }
      }
    }
    .jumbotron {
      height: 750px;
      @media #{$small-only} {
        background-position: 0 0 !important;
        height: 650px;
      }

      .content {
        @media #{$medium-up} {
          margin-top: 100px;
        }

        .htl, .drupal-block {
          margin-top: 10px;
        }
        h1 {
          font-size: rem-calc(33);
          font-weight: 500;
          margin-bottom: 0;
          @media #{$medium-up} {
            font-size: rem-calc(35);
          }
        }
        h2 {
          font-size: rem-calc(35);
          color: $Aggdata_blue;
          margin-bottom: 0;
          margin-top: 0;
        }
        .drupal-block {
          .block-row {
            margin-top: 0;
          }
        }
      }
    }
  }
  .title {
    text-align: center;
  }
  .ruled-title .title {
    margin-top: 30px;
    overflow: hidden;
    text-align: center;

    h2 {
      font-size: rem-calc(14);
      text-transform: uppercase;
      color: #999;
      position: relative;
      display: inline-block;
      
      &:before, &:after {
        content: '';
        position: absolute;
        top: 50%;
        border-bottom: 1px solid #999;
        width: 600px;
        margin: 0 15px;
      }
      &:before {
        right: 100%;
      }
      &:after {
        left: 100%;
      }
    }
  }
  .logo-icon-title .title {
    &:before {
      content: '';
      display: block;
      width: 43px;
      height: 61px;
      margin: 0 auto;
      background: url("#{$img-path}/logo-icon.svg") no-repeat;
      background-size: 43px;
    }
  }
  .panels_container {
    margin: 30px 0;

    .inner-container {
      background: white;
      padding: 30px;
      border-radius: 4px;
      box-shadow: 0px 0px 10px 2px #ccc;

      .list {
        h3 {
          font-size: rem-calc(24);
          color: $Aggdata_blue;
          margin-bottom: 0;
          margin-top: rem-calc(32);

          i.fa {
            color: $Aggdata_dkgreen;
          }
          p {
            line-height: rem-calc(28);
          }
        }
      }
      .media__object {
        img {
          width: 100%;
        }
      }
    }
  }
  .media_container {
    .paragraphs-item-image {
      .content {
        @media #{$medium-up} {
          padding: 0;
        }

        img {
          width: 100%;
        }
      }
    }
  }
  .drupal-block {
    margin: 30px 0;
    .block-row {
      margin-top: 30px;
    }
  }
  .true-5-grid {
    .grid {
      @media #{$medium-up} {
        .medium-offset-1 {
          margin-left: 0 !important;
        }
        .medium-2:nth-child(even) {
          width: 25%;
        }
      }
    }
  }
  .agg-footer-dynamic {
    background: url("#{$img-path}/dynamic-page-footer.jpg") no-repeat;
    background-position: center center;
    background-size: cover;
    min-height: 450px;

    .l-footer-columns {
      @media #{$small-only} {
        text-align: center; 
      }
      .site-name {
        display: inline-block;

        a {
          display: block;
          width: 180px;
          height: 56px;
          text-indent: 200%;
          white-space: nowrap;
          overflow: hidden;
          background: url("#{$img-path}/logo-whitetext.svg") no-repeat;
          background-size: 180px;
        }
      }
      .site-slogan {
        font-size: rem-calc(18);
        color: $Aggdata_green;
        vertical-align: top;
        margin-top: 16px;
        @media #{$large-up} {
          display: inline-block;
          border-left: 1px solid white;
          margin-left: 20px;
          padding-left: 20px;
        }
      }
      .footer-second, .footer-third {
        font-weight: 700;

        label {
          color: white;
          text-transform: uppercase;
        }
        a {
          color: $Aggdata_green;
        }
      }
    }
    .l-footer-copyright {
      margin-top: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-top: 1px solid white;
      color: white;
      font-size: rem-calc(14);
    }
    .l-footer-contact {
      padding-top: 50px;
      padding-bottom: 70px;
      text-align: center;
      h2 {
        color: white;
      }
      p {
        color: $Aggdata_green;
      }
      .webform-component--name,
      .webform-component--email {
        display: inline-block;
        position: relative;
        margin: 5px;
        @media #{$medium-up} {
          right: -4px;
          margin: 0;
        }
        input {
          height: 50px;
          border-radius: 0;
          border-width: 0;
          margin: 0;
          box-shadow: none;
        }
      }
      .webform-component--name {
        @media #{$medium-up} {
          right: -8px;
          input {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
        }
      }
      .webform-component--email {
        @media #{$medium-up} {
          input {
            border-left: 1px solid #e1e1e1;
          }
        }
      }
      button, .button {
        display: inline-block;
        line-height: 1.1;
        font-weight: 400;
        margin: 5px;
        @media #{$medium-up} {
          border-radius: 0 4px 4px 0;
          margin: 0;
        }
        &:before {
          color: white;
          content: "\f0a9";
          font-family: FontAwesome;
          font-size: rem-calc(14);
          margin-right: 10px;
        }
      }
    }
  }
  #landing-search-data {
    margin-top: 20px;
    border-radius: 4px;
    padding: 10px 20px;
    font-weight: 500;

    &:before {
      content: "\f0aa";
      font-family: FontAwesome;
      margin-right: 5px;
    }
  }
  .block-views-testimonial-slider-block-1 {
    .views-row {
      margin-bottom: 30px;
      .views-field-body {
        background: white;
        padding: 20px;
        border-radius: 4px;
        box-shadow: 0px 0px 10px 2px #ccc;
        font-size: rem-calc(15);
        font-weight: bold;
        font-family: Lato, sans-serif;
        font-style: italic;
        line-height: rem-calc(28);
        position: relative;
        &:after {
          content: "";
          position: absolute;
          box-shadow: #ccc 2px 2px 4px ;
          -moz-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          bottom: -10px;
          left: 25px;
          border-width: 10px;
          border-style: solid;
          border-color: transparent #FFF #FFF transparent;
        }
      }
      .views-field-field-testimonial-name {
        margin-top: 30px;
        .image img {
          border-radius: 50%;
        }
        .name {
          color: $Aggdata_dkgreen;
          font-size: rem-calc(22);
        }
        .position {
          color: $Aggdata_blue;
        }
      }
    }
  }
  #search-block-form {
    margin-bottom: 0;
    margin-left: rem-calc(15);
    margin-right: rem-calc(15);

    .form-type-textfield {
      input {
        margin: 0;
        width: 100%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-color: #ccc;
        box-shadow: 0px 0px 10px 2px #ccc;
        font-weight: 300;
        font-size: rem-calc(19);
      }
    }
    .button.form-submit {
      margin: 0;
      text-indent: 27px;
      width: 80px;
      line-height: 1.75;
      background-color: $Aggdata_blue;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      box-shadow: 2px 0px 10px 2px #ccc;
      &:before {
        color: white;
        content: "\f002";
        font-family: FontAwesome;
        font-size: rem-calc(28);
        margin-right: 80px;
      }
    }
  }
  ::-webkit-input-placeholder { /* Chrome */
    color: $Aggdata_blue;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $Aggdata_blue;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $Aggdata_blue;
    opacity: 1;
  }
  :-moz-placeholder { /* Firefox 4 - 18 */
    color: $Aggdata_blue;
    opacity: 1;
  }
  .recent-purchase {
    strong {
      color: $Aggdata_blue;
    }
    a {
      color: $Aggdata_dkgreen;
    }
  }
  h2 {
    margin-bottom: rem-calc(8);
  }
}
.node-type-retail-news {

  .callout {
    background: #0e6895 url("../images/bg-retail-news.png") no-repeat center center;
    color: white;
    text-align: center;
    padding-top: rem-calc(40);
    padding-bottom: rem-calc(24);
    
    p {
      color: white;
    }

    .preheader {
      font-family: Lato, sans-serif;
      font-style: italic;
      font-size: rem-calc(24);
    }

    .headline {
      color: white;
      font-weight: bold;
      margin-bottom: 0;
    }

    .header-logo {
      margin-bottom: rem-calc(10);

      a {
        display: block;
        width: 200px;
        height: 65px;
        text-indent: 200%;
        white-space: nowrap;
        overflow: hidden;
        background: url("#{$img-path}/logo-whitetext.svg") no-repeat;
        background-size: 200px;
        margin-top: rem-calc(-10);
        margin-bottom: rem-calc(-10);
        margin-left: auto;
        margin-right: auto;
      }
    }

    .slogan {
      font-family: Lato, sans-serif;
      font-style: italic;
      font-size: rem-calc(24);
    }
  }

  .body.field {
    margin: 0 auto;
		max-width: 68.75rem;
		padding: $column-gutter ($column-gutter/2);
    @media #{$small-only} {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  .main {
    a, a span, a font {
      color: $Aggdata_blue !important;
    }
  }

  .l-main-bottom {
    margin-bottom: rem-calc(30);

    .retail-news-stats {
      margin-bottom: rem-calc(24);
    }

    .retail-news-credits p {
      color: #2fb44c;
      font-family: verdana,geneva,sans-serif;
    }

    p {
      font-family: 'Hind Siliguri', sans-serif;
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: #666666;
    }
  }
}

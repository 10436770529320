.form-item {
	input[type="text"],
	input[type="password"],
	input[type="email"],
	textarea {
		font-size: rem-calc(17);
		line-height: rem-calc(26);
		border: 1px solid $dksmoke;
		border-radius: 7px;
		background-color: $ghost;
		padding: 0.5rem;
    width: 100%;
    @media #{$medium-up} {
      max-width: 300px;
    }
	}

	input[type="checkbox"] {
		appearance: none;
		-webkit-appearance: none;
		border: 1px solid $dksmoke;
		padding: rem-calc(15);
		border-radius: 7px;
		display: inline-block;
		position: relative;
		vertical-align: middle;
		margin-bottom: 0;
		outline: none;
    width: 10px;
	}

	input[type="checkbox"] + label {
		display: inline-block;
		vertical-align: top;
		font-size: rem-calc(18);
		line-height: rem-calc(24);
		color: $steel;
    padding: 0;
    margin: 0 0 0 rem-calc(5);
    width: 80%;
	}

	input[type="checkbox"]:checked:after {
		content: '';
		width: 17px;
		height: 14px;
		font-size: rem-calc(20);
		position: absolute;
		top: rem-calc(7);
		left: rem-calc(8);
		color: $base;
		background: url("#{$img-path}/icons/check_mark.gif") no-repeat;
	}

	input[type="checkbox"]:checked {
		background-color: $ghost;
		border: 1px solid $dksmoke;
		color: $jumbo;
	}

	label {
		font-size: rem-calc(16);
		line-height: rem-calc(21);
		padding-bottom: rem-calc(5);
		color: $jumbo;
	}

	select.form-select {
		background-color: $ghost;
		border: 1px solid $dksmoke;
		color: $jumbo;
		border-radius: 7px !important;
    width: 100%;
    @media #{$medium-up} {
      max-width: 280px;
    }
	}

  input[type="radio"] {
    vertical-align: top;
    position: relative;
    top: rem-calc(3);
	}

	input[type="radio"] + label {
    width: 85%;
	}
}

div.addressfield-container-inline > div.form-item {
   @media #{$small-only} {
     float: none;
     margin-right: 0;
   }
}

#search-block-form {

	&::after {
		content: "";
		display: table;
		clear: both;
	}

	.ui-autocomplete-input {
		background: none;
	}

	.container-inline {
		width: 100%;
		float: right;
	}

	.form-type-textfield {
		input {
			max-width: 420px;
			border-radius: 5px;
			font-size: rem-calc(14);
			line-height: rem-calc(27);
			background-color: $ghost;
			top: 0; // Overwrite default foundation .topbar .button top positioning.
		}
	}

	.button.form-submit {
		border-radius: 5px;
		font-size: rem-calc(14);
		line-height: rem-calc(20);
		height: 52px;
		margin-right: 0;
		top: 0; // Overwrite default foundation .topbar .button top positioning.
    @media #{$medium-up} {
		  font-size: rem-calc(16);
    }
	}

	.small-8 {
		input {
      width: 95%;
			height: 52px;
			padding: rem-calc(10) rem-calc(20);
		}
	}
}

//******** The contact us form *******//
#webform-client-form-37 {
	.webform-component--first-name,
	.webform-component--last-name,
	.webform-component--email-address,
	.webform-component--phone-number {
		width: 48%;
		float: left;
		margin: 0;
	}

	.webform-component--message-subject {
		margin: 0;
	}

	.webform-component--first-name,
	.webform-component--email-address {
		margin-right: 4%;
	}
  .form-item {
    input[type="text"],
    input[type="password"],
    input[type="email"],
    textarea {
      max-width: inherit;
    }
  }
}

//******** The whitepaper form *******//
#webform-client-form-4004 {
  max-width: 500px;

	.webform-component--first-name,
	.webform-component--last-name,
	.webform-component--company,
	.webform-component--phone-number {
		width: 48%;
		float: left;
		margin: 0;
	}

	.webform-component--first-name,
	.webform-component--company {
		margin-right: 4%;
	}

  .form-item {
    input[type="text"],
    input[type="password"],
    input[type="email"],
    textarea {
      max-width: inherit;
    }
  }
}
.ctools-modal-content #webform-client-form-4004 {
  max-width: inherit;
}
.section-product-overview #webform-client-form-4004 {
  .webform-component {
    width: 100%;
    margin-right: 0;
    float: none;
  }
}


.block-aggdata-general-find-out-how-form {
  .range-slider-wrapper {
    margin-bottom: rem-calc(30);
  }
	.range-slider-labels {
    position: relative;
    @media #{$small-only} {
      margin-top: rem-calc(30);
    }
		&:after {
			content: "";
			display: table;
			clear: both;
		}

		> div {
			width: 16%;
      text-align: center;
      @media #{$small-only} {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
        text-align: left;
        white-space: nowrap;
      }
			float: left;
		}
		.opt7 {
			text-align: right;
      position: absolute;
      top: 0;
      right: 0;
      @media #{$small-only} {
        right: -10px;
      }
		}
    .opt1 {
			text-align: left;
      position: absolute;
      top: 0;
      left: 0;
      @media #{$small-only} {
        left: -10px;
      }
		}
    .opt2 {
			margin-left: 10%;
		}
	}

	.range-slider2-labels {
		display: table;
		width: 100%;

		&:after {
			content: "";
			display: table;
			clear: both;
		}

		> div {
			display: table-cell;
			width: 25.0%;
			float: left;
		}

		.opt5 {
			text-align: right;
			width: 0%;
		}
	}

	.q1-prefix,
	.form-item-question1-update,
	.q1-suffix {
		float: left;
	}

  .form-item-question1-update {
    width: 130px;
  }

	.q1-prefix,
	.q1-suffix {
		padding-top: rem-calc(20);
	}

	.q1-prefix {
		padding-right: rem-calc(10);
	}

	.q1-suffix {
		padding-left: rem-calc(10);
	}

	#aggdata-general-findouthow-form > div > .form-item-question1-history {
		clear: both;
		label {
			color: $jet;
		}
	}

	.form-item-question3-usecase-other,
	.form-item-question2-tools-other {
		margin-left: rem-calc(60);
    input {
      max-width: 300px;
    }
	}

	h3 {
		clear: both;
    margin-top: rem-calc(50);
    &:first-child {
      margin-top: 0;
    }
	}

	.form-checkboxes {
		padding-top: rem-calc(10);
		padding-left: rem-calc(20);

		> div {
			margin-bottom: rem-calc(10);
		}
	}

	.counter {
		padding-top: rem-calc(15);
	}

	.result-title {
		text-align: center;
		padding-top: rem-calc(30);
		padding-bottom: rem-calc(30);
	}

	.trigger-ajax-wrapper {
    text-align: center;
    a {
      display: inline-block;
      margin: 0 auto;
      margin-bottom: rem-calc(30);
    }
	}

	.q5-tip {
		display: block;
		padding: rem-calc(20);
		border: 1px solid $Aggdata_blue;

		p {
			margin: 0;
		}
	}

	#result-wrapper {
    margin-top: rem-calc(50);
		margin-bottom: rem-calc(50);

    .cta {
      color: $white;
      padding: 0;
      padding-bottom: rem-calc(36);
      background-color: $white;
      border: 2px solid $dksmoke;
      border-top: 0;
      position: relative;
      margin: 0 auto;
      text-align: center;

      h3 {
        width: 100%;
        background-color: $Aggdata_blue;
        margin: 0 0 rem-calc(30);
        padding: rem-calc(35) rem-calc(15);
        font-size: rem-calc(30);
        line-height: rem-calc(34);
        font-weight: bold;
        text-transform: uppercase;
        color: $white;
      }

      p {
        font-size: rem-calc(18);
        line-height: rem-calc(24);
        width: percentage(221/314);
        font-weight: 400;
        color: $steel;
        text-align: center;
        display: inline-block;
        margin: 0;
        padding: 0;
      }

      .button {
        font-size: rem-calc(18);
        line-height: rem-calc(25);
        font-weight: bold;
        width: percentage(250/314);
        position: absolute;
        bottom: -25px;
        left: 0;
        right: 0;
        display: block;
        margin: 0 auto;
      }

      &.premium {
        h3 {
          font-size: rem-calc(38);
          line-height: rem-calc(43);
          background-color: $Aggdata_green;
        }

        .button {
          width: percentage(300/480);
          background-color: $Aggdata_green;

          &:hover {
            background-color: darken($Aggdata_green, 10%);
          }
        }
      }
    }
	}
}

@media #{$small-only} {
  #modalContent {
    left: 3% !important;
    max-width: 94%;
    .ctools-modal-content {
      max-width: 100%;
      .modal-content {
        max-width: 100%;
      }
    }
  }
}

#ui-theme-classic,
ul[data-sa-theme="minimal"].ui-menu {
  border: 1px solid $grey;
  min-width: 100px !important;
  max-width: 330px !important;
  max-height: 425px;
  overflow-y: scroll;

  .ui-menu-item {
    font: inherit;
    font-size: rem-calc(14);
    line-height: inherit;
    cursor: pointer;
    padding: rem-calc(5) rem-calc(10);

    &.ui-menu-item-odd {
      background-color: $smoke;
    }

    a {
      color: $Aggdata_blue;
      padding: 0;
    }

    &:hover {
      background-color: $Aggdata_dkblue;
      color: white;

      a {
        color: $white;
      }
    }
  }
}

#ui-theme-classic .ui-state-focus,
ul[data-sa-theme="minimal"].ui-menu .ui-state-focus {
  font-weight: normal;
  border: none;
  background-image: none;
  background-color: transparent;
}

ul[data-sa-theme="minimal"] .ui-menu-item-first {
  border-top-color: $grey;
}

.webform-client-form {
  .description {
    display: none;
  }
}
.webform-client-form {
  .webform-component-markup {
    .description {
      display: block;
    }
  }
}

div.ctools-modal-content {
  height: auto !important;
  .modal-content {
    height: auto !important;
  }
}
#modalContent {
  margin-top: -150px;
}

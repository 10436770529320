.commerce-add-to-cart-confirmation {
  background-color: white;
  border-radius: $global-radius;
  -moz-box-shadow: 0 2px 15px #57595a;
  -webkit-box-shadow: 0 2px 15px #57595a;
  box-shadow: 0 2px 15px #57595a;
  filter: progid:DXImageTransform.Microsoft.Shadow(color='#57595A', direction='180', strength='5');
  color: $steel;
  overflow: hidden;
  padding: 0;
  width: 70%;
  z-index: 201;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;

  ul {
    margin: 0;
  }

  .message-inner {
    /* Close button */
    .commerce-add-to-cart-confirmation-close {
      background: url('../images/btn_add-to-cart-close.png') no-repeat 0 0;
      display: block;
      height: 17px;
      position: absolute;
      right: 15px;
      top: 15px;
      width: 18px;
    }
    /* Title */
    .added-product-title {
      color: $Aggdata_blue;
      font-weight: bold;
      font-size: rem-calc(28);
      padding: 40px 30px 0;
      text-transform: uppercase;
      @media #{$medium-up} {
        float: left;
        width: 60%;
      }
    }

    /* Button */
    .button-wrapper {
      background-color: lighten($Aggdata_blue, 45%);
      height: 100%;
      padding: 40px 30px;
      @media #{$medium-up} {
        position: absolute;
        right: 0;
        width: 40%;
      }

      &:after {
        clear: both;
        content: ".";
        display: block;
        height: 0;
        visibility: hidden;
      }
      .button {
        display: block;
        padding: 0;
        width: 100%;

        a {
          display: block;
          padding: 1rem 2rem 1.0625rem 2rem;
          color: white;
        }
      }
      .button.checkout {
        background-color: $Aggdata_blue;

        &:hover {
          background-color: darken($Aggdata_blue, 10%);
        }
      }
      .button.continue {
        background-color: $grey;

        &:hover {
          background-color: darken($grey, 10%);
        }

        .commerce-add-to-cart-confirmation-close {
          background: none;
          height: auto;
          position: static;
          width: auto;
          display: block;
          padding: 1rem 2rem 1.0625rem 2rem;
        }
      }
    }
  }

  /* Product display */
  .view-confirm-message-product-display {
    clear: both;
    @media #{$medium-up} {
      width: 60%;
    }

    .view-content {
      padding: 30px;
      position: relative;

      .commerce-product-title {
        font-size: rem-calc(18);

        .commerce-product-title-label {
          display: none;
        }
      }
      .commerce-product-sku {
        font-size: rem-calc(14);

        .commerce-product-sku-label {
          display: inline;
          padding-right: 5px;
        }
      }
      .views-field-commerce-total {
        clear: both;
        font-size: rem-calc(20);
        margin-top: 25px;

        .views-label {
          padding-right: 5px; /* LTR */
        }
        .field-content {
          color: $Aggdata_blue;
          display: inline;
        }
      }
      .views-field-quantity {
        display: none;
      }
    }
  }
}

.page-checkout {
  .commerce-add-to-cart-confirmation, 
  div.commerce_add_to_cart_confirmation_overlay {
    display: none;
  }
}
